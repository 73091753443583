.reset-password {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100vh;
}

.reset-password .card {
    width: 600px;
    padding: 40px 120px;
    margin: 30px 0;
    text-align: center;
}

.reset-password .card .company-logo {
    width: 150px;
}

.reset-password .card h4 {
    margin-top: 30px;
}

.reset-password .card .illustration {
    margin-top: 20px;
    width: 220px;
}

.reset-password .card .password-input {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.reset-password .card .password-input input {
    width: 100%;
    font-family: inherit;
    padding: 12px 15px;
    margin-bottom: 15px;
    outline: 0;
    border: 1px solid #cccbcb;
    border-radius: 5px;
}

.reset-password .card .password-input .btn {
    width: 100%;
    margin-top: 10px;
    font-size: 0.875rem;
}

@media screen and (max-width: 1200px) {
    .reset-password .card .company-logo {
        width: 170px;
    }
}

@media screen and (max-width: 700px) {
    .reset-password .card {
        width: 90%;
        padding: 35px;
    }
}
@media screen and (max-width: 500px) {
    .reset-password .card .illustration {
        width: 180px;
    }

    .reset-password .card .company-logo {
        width: 150px;
    }

    .reset-password .card h4 {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 400px) {
    .reset-password .card .company-logo {
        width: 150px;
    }

    .reset-password .card .password-input input {
        font-size: 0.75rem;
        padding: 10px 12px;
    }

    .reset-password .card .password-input .btn {
        font-size: 0.75rem;
    }
}

@media screen and (max-width: 330px) {
    .reset-password .card {
        width: 90%;
        padding: 35px 20px;
    }

    .reset-password .card h4 {
        font-size: 1.2rem;
    }
}
