.Toastify__toast-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    font-family: 'Poppins', sans-serif;
}

.Toastify__toast {
    width: 20%;
    position: fixed;
    top: 5%;
    right: 5%;
}

@media screen and (max-width: 700px) {
    .Toastify__toast {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 1000;
        font-family: 'Poppins', sans-serif;
        width: 100%;
        height: 50px;
        top: 0;
        right: 0;
    }
}
