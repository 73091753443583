/* Sign Up and Sign In */

.sign-up-mobile,
.sign-in-mobile {
    display: none;
}

.sign-up.flex,
.sign-in.flex {
    flex: 1;
}

.error-field {
    margin: 0;
    padding: 0;
    margin-top: -5px;
    margin-bottom: 10px;
    padding-left: 10px;
    color: red;
}

.sign-up a,
.sign-in a {
    color: var(--primary-color);
}

.left-side {
    flex: 0.5;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 100%;
    height: 100vh;
    border-top-right-radius: 8%;
    border-bottom-right-radius: 8%;
    padding: 0 10%;
    -webkit-filter: drop-shadow(20px 4px 80px rgba(44, 70, 129, 0.2));
    filter: drop-shadow(20px 4px 80px rgba(44, 70, 129, 0.2));
}

.glass {
    background: linear-gradient(140.7deg, rgba(224, 224, 224, 0.48) 3.81%, rgba(255, 255, 255, 0.6) 95.46%);
    box-shadow: 0px 0px 24px -1px rgba(53, 101, 224, 0.25);
    -webkit-backdrop-filter: blur(260px);
    backdrop-filter: blur(260px);
    border-radius: 10%;
    padding: 55px;
    padding-bottom: 100px;
    z-index: 1;
}

.glass .login_logo {
    display: flex;
    position: relative;
    width: 160px;
}

.glass .login_logo::after {
    content: '';
    position: absolute;
    top: 55px;
    width: 70%;
    height: 5px;
    background: linear-gradient(90deg, #e2801c -3.42%, #f7a654 107.37%);
}

.glass h3 {
    margin-top: 50px;
    color: var(--text-light-color);
}

.glass > p:nth-child(3) {
    color: var(--primary-color);
    font-size: 1.1rem;
}

.glass .quote {
    position: relative;
    color: var(--primary-color);
    margin-top: 70px;
}

.glass .quote::before {
    content: '“';
    position: absolute;
    top: -100px;
    left: -6px;
    font-family: var(--secondary-font-family);
    font-size: 8rem;
    color: var(--primary-color);
}

.glass .quote p {
    font-size: 1rem;
    color: #555454;
}

.glass .quote span {
    display: flex;
    justify-content: flex-end;
    font-size: 1.125rem;
}

.right-side {
    flex: 0.5;
    padding: 0 8%;
    font-size: 0.875rem;
}

.sign-up .right-side > div {
    width: 100%;
    padding: 0 3%;
}

.sign-in .right-side > div {
    width: 100%;
    padding: 0 15%;
}

.right-side > div > * {
    margin: 10px 0;
}

.right-side h1 {
    margin-bottom: 30px;
}

.right-side .btn {
    font-size: 0.875rem;
    border-radius: 10px;
}

.right-side .btn.google,
.right-side .btn.apple,
.right-side .btn.facebook {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    padding: 15px;
}

.right-side .google img,
.right-side .apple img,
.right-side .facebook img {
    width: 20px;
    margin-right: 10px;
}

.right-side .divider {
    position: relative;
    margin: 20px 0px;
}

.right-side .line::before {
    content: '';
    position: absolute;
    left: 0;
    width: 45%;
    height: 1px;
    background-color: var(--text-primary-color);
}

.right-side .divider .line::after {
    content: '';
    position: absolute;
    right: 0;
    width: 45%;
    height: 1px;
    background-color: var(--text-primary-color);
}

.right-side .divider span {
    position: absolute;
    top: -10px;
    left: 48%;
}

.right-side form {
    margin-top: 40px;
}

.right-side input {
    font-family: inherit;
    font-size: 0.875rem;
    margin-bottom: 10px;
    padding: 15px;
    width: 100%;
    border: 1px solid var(--text-primary-color);
    border-radius: 10px;
    outline: 0;
}

.input {
    font-family: inherit;
    font-size: 0.875rem;
    margin-bottom: 10px;
    padding: 15px;
    width: 100%;
    border: 1px solid var(--text-primary-color);
    border-radius: 10px;
    outline: 0;
}

.sign-up .right-side .name,
.sign-up .right-side .password-container {
    -webkit-column-gap: 8px;
    column-gap: 8px;
}

.right-side .password-div {
    position: relative;
    width: 100%;
}

.right-side .password-div input {
    padding-right: 55px;
}

.sign-up .right-side .password-div img {
    position: absolute;
    width: 18px;
    top: 18px;
    right: 20px;
    cursor: pointer;
}

.sign-in .right-side .password-div img {
    position: absolute;
    width: 20px;
    top: 16px;
    right: 20px;
    cursor: pointer;
}

/* .right-side .password-div #password-show,
.sign-up .right-side .password-div #confirm-password-show {
  visibility: hidden;
} */

.sign-up .right-side .terms,
.sign-in .right-side .links {
    margin-bottom: 10px;
}

.sign-up .right-side .terms label {
    color: var(--text-dark-color);
}

.sign-up .right-side .terms input[type='checkbox'],
.sign-in .right-side .links input[type='checkbox'] {
    position: relative;
    top: 4px;
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

.sign-in .links.flex {
    justify-content: space-between;
    align-items: center;
}

.sign-in .right-side .links label {
    position: relative;
    top: 1px;
    color: var(--text-dark-color);
}

#forgot-password-text {
    color: var(--primary-color);
    cursor: pointer;
}

.right-side button[type='submit'] {
    width: 100%;
    padding: 16px;
}

.right-side .end {
    color: var(--text-dark-color);
}

#resend-email-text {
    display: block;
    margin-top: 5px;
    color: var(--primary-color);
    cursor: pointer;
}

.additional-details-modal,
.forgot-password-modal,
.resend-email-modal {
    display: none;
}

.additional-details-modal h6 {
    font-weight: 500;
}

.additional-details-modal .user-img img {
    width: 20%;
}

.additional-details-modal input,
.forgot-password-modal input,
.resend-email-modal input {
    font-family: inherit;
    font-size: 0.875rem;
    width: 100%;
    margin-top: 10px;
    padding: 10px 8px;
    border: 1px solid var(--text-primary-color);
    border-radius: 5px;
    outline: 0;
}

.additional-details-modal .error,
.forgot-password-modal .error,
.resend-email-modal .error {
    margin-top: 10px;
    color: #e44042;
}

/* Responsiveness */

/* 4K Displays */

/* 5K Displays */

@media screen and (min-width: 4800px) {
    .glass {
        width: 50%;
    }
}

@media screen and (min-width: 2800px) {
    .glass {
        width: 70%;
    }
}

/* 2K Displays */

@media screen and (min-width: 1920px) {
    .glass {
        width: 88%;
    }

    .glass .login_logo {
        width: 220px;
    }

    .glass .login_logo::after {
        top: 70px;
    }

    .glass .quote::before {
        font-size: 9rem;
        top: -105px;
    }

    .glass .quote p {
        font-size: 1.4rem;
    }

    .glass .quote span {
        font-size: 1.4rem;
    }

    .right-side {
        font-size: 1.125rem;
    }

    .sign-up .right-side > div {
        padding: 0 15%;
    }

    .sign-in .right-side > div {
        padding: 0 15%;
    }

    .right-side > div > * {
        margin: 20px 0;
    }

    .right-side h1 {
        margin-bottom: 40px;
    }

    .right-side .btn {
        font-size: 1.125rem;
        border-radius: 10px;
    }

    .right-side .google img,
    .right-side .apple img,
    .right-side .facebook img {
        width: 25px;
        margin-right: 15px;
    }

    .right-side .divider {
        margin: 25px 0px;
    }

    .right-side .divider span {
        top: -14px;
    }

    .right-side form {
        margin-top: 45px;
    }

    .right-side input {
        font-size: 1.125rem;
        margin-bottom: 20px;
    }

    .sign-up .right-side .password-div img {
        width: 20px;
        top: 20px;
    }

    .sign-in .right-side .password-div img {
        width: 22px;
        top: 18px;
    }
}

/* Medium Laptops */

@media screen and (max-width: 1400px) {
    .left-side {
        padding: 0 8%;
    }
    .glass {
        padding: 55px 40px;
        padding-bottom: 100px;
    }
}

/* For large tablet devices */

@media screen and (max-width: 1200px) {
    .sign-up,
    .sign-in {
        display: none;
    }

    .sign-up-mobile a,
    .sign-in-mobile a {
        color: var(--primary-color);
    }

    .sign-up-mobile,
    .sign-in-mobile {
        display: flex;
        height: 100vh;
        font-size: 1.2rem;
    }

    .mobile_wrapper h1 {
        font-size: 2.5rem;
    }

    .sign-up-mobile {
        padding: 0 25%;
    }

    .sign-in-mobile {
        padding: 0 25%;
    }

    .sign-up-mobile h1,
    .sign-in-mobile h1 {
        font-size: 2.5rem;
    }

    .sign-up-mobile .login_logo img,
    .sign-in-mobile .login_logo img {
        margin-bottom: 45px;
        width: 50% !important;
    }

    .sign-up-mobile .login_logo img {
        width: 38%;
    }

    .sign-in-mobile .login_logo img {
        width: 50%;
    }

    .sign-up-mobile > div > *,
    .sign-in-mobile > div > * {
        margin: 10px 0;
    }

    .sign-up-mobile h1,
    .sign-in-mobile h1 {
        margin-bottom: 25px;
    }

    .sign-up-mobile .btn,
    .sign-in-mobile .btn {
        font-size: 1.2rem;
        border-radius: 10px;
    }

    .sign-up-mobile .btn.google,
    .sign-up-mobile .btn.apple,
    .sign-up-mobile .btn.facebook,
    .sign-in-mobile .btn.google,
    .sign-in-mobile .btn.apple,
    .sign-in-mobile .btn.facebook {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
        padding: 15px;
        font-size: 1.2rem;
    }

    .sign-up-mobile .google img,
    .sign-up-mobile .apple img,
    .sign-up-mobile .facebook img,
    .sign-in-mobile .google img,
    .sign-in-mobile .apple img,
    .sign-in-mobile .facebook img {
        width: 25px;
        margin-right: 10px;
    }

    .sign-up-mobile .divider,
    .sign-in-mobile .divider {
        position: relative;
        margin: 20px 0px;
    }

    .sign-up-mobile .line::before,
    .sign-in-mobile .line::before {
        content: '';
        position: absolute;
        left: 0;
        width: 45%;
        height: 0.5px;
        background-color: var(--primary-color);
    }

    .sign-up-mobile .divider .line::after,
    .sign-in-mobile .divider .line::after {
        content: '';
        position: absolute;
        right: 0;
        width: 45%;
        height: 0.55px;
        background-color: var(--primary-color);
    }

    .sign-up-mobile .divider,
    .sign-in-mobile .divider {
        margin: 30px;
    }

    .sign-up-mobile .divider span,
    .sign-in-mobile .divider span {
        position: absolute;
        top: -14px;
        left: 46%;
        padding: 0 10px;
        color: var(--primary-color);
        font-size: 1.2rem;
    }

    .sign-up-mobile form,
    .sign-in-mobile form {
        margin-top: 60px;
    }

    .sign-up-mobile input,
    .sign-in-mobile input {
        font-family: var(--primary-font);
        font-size: 1.2rem;
        margin-bottom: 10px;
        padding: 15px;
        width: 100%;
        border: 1px solid var(--primary-color);
        border-radius: 10px;
        outline: 0;
    }

    .sign-up-mobile .name,
    .sign-up-mobile .password-container {
        -webkit-column-gap: 6px;
        column-gap: 6px;
    }

    .sign-up-mobile .password-div,
    .sign-in-mobile .password-div {
        position: relative;
        width: 100%;
    }

    .sign-up-mobile .password-div input,
    .sign-in-mobile .password-div input {
        padding-right: 55px;
    }

    .sign-up-mobile .password-div img {
        position: absolute;
        width: 25px;
        top: 18px;
        right: 20px;
        cursor: pointer;
    }

    .sign-in-mobile .password-div img {
        position: absolute;
        width: 25px;
        top: 16px;
        right: 20px;
        cursor: pointer;
    }

    .sign-up-mobile .password-div #password-show-mobile,
    .sign-in-mobile .password-div #password-show-mobile,
    .sign-up-mobile .password-div #confirm-password-show-mobile {
        visibility: hidden;
    }

    .sign-up-mobile .terms,
    .sign-in-mobile .links {
        margin: 15px 0px;
    }

    .sign-up-mobile .terms label,
    .sign-in-mobile .links label {
        color: var(--text-dark-color);
    }

    .sign-up-mobile .terms input[type='checkbox'],
    .sign-in-mobile .links input[type='checkbox'] {
        position: relative;
        top: 4px;
        width: 25px;
        height: 20px;
        margin-right: 8px;
    }

    .sign-in-mobile .links.flex {
        justify-content: space-between;
        align-items: center;
    }

    .sign-up-mobile button[type='submit'],
    .sign-in-mobile button[type='submit'] {
        width: 100%;
        padding: 16px;
    }

    .sign-up-mobile .end,
    .sign-in-mobile .end {
        color: var(--text-dark-color);
    }

    #forgot-password-text {
        color: var(--primary-color);
        cursor: pointer;
    }

    #resend-email-text {
        display: block;
        margin-top: 3px;
        color: var(--primary-color);
        cursor: pointer;
    }

    /* .sign-in-mobile,
    .sign-up-mobile {
        background: linear-gradient(156.47deg, #f8f8f8 3.8%, rgba(202, 217, 255, 0.86) 118.91%);
    } */
}

.mobile_wrapper {
    width: 100%;
}

/* For medium tablet devices */

@media screen and (max-width: 1000px) {
    .sign-up-mobile {
        padding: 0 15%;
    }

    .sign-in-mobile {
        padding: 0 20%;
    }

    .sign-up-mobile .login_logo img {
        width: 45% !important;
    }

    .sign-up-mobile .divider span {
        left: 46%;
    }

    .sign-in-mobile .divider span {
        left: 45%;
    }
}

/* For small tablet devices */

@media screen and (max-width: 700px) {
    .sign-up-mobile {
        padding: 0 5%;
    }

    .sign-in-mobile {
        padding: 0 10%;
    }

    .sign-up-mobile .login_logo img {
        margin-bottom: 20px;
    }

    .sign-up-mobile,
    .sign-in-mobile {
        height: auto;
    }

    .sign-in-mobile .login_logo img {
        margin-bottom: 35px;
    }

    .sign-up-mobile .divider span {
        left: 45%;
    }

    .sign-up-mobile .btn.google,
    .sign-up-mobile .btn.apple,
    .sign-up-mobile .btn.facebook,
    .sign-in-mobile .btn.google,
    .sign-in-mobile .btn.apple,
    .sign-in-mobile .btn.facebook {
        font-size: 1.1rem;
    }

    .sign-up-mobile .google img,
    .sign-up-mobile .apple img,
    .sign-up-mobile .facebook img,
    .sign-in-mobile .google img,
    .sign-in-mobile .apple img,
    .sign-in-mobile .facebook img {
        width: 22px;
    }

    .sign-up-mobile,
    .sign-in-mobile {
        font-size: 1.1rem;
    }

    .sign-up-mobile input,
    .sign-in-mobile input {
        font-size: 1.1rem;
    }

    .sign-up-mobile .btn,
    .sign-in-mobile .btn {
        font-size: 1.1rem;
    }

    .sign-up-mobile h1,
    .sign-in-mobile h1 {
        font-size: 2rem;
    }

    .sign-up-mobile .flex,
    .sign-in-mobile .flex {
        align-items: flex-start;
    }

    .sign-up-mobile,
    .sign-in-mobile {
        margin: 30px 0;
    }

    .already-have {
        margin: 10px 0 50px !important;
    }

    .sign-up-mobile .login_logo img {
        width: 40% !important;
    }

    .sign-in-mobile .login_logo img {
        width: 45% !important;
    }
}

/* For large mobile devices */

@media screen and (max-width: 500px) {
    .sign-up-mobile {
        font-size: 0.75rem;
        padding: 0 5%;
    }

    .sign-in-mobile .divider span {
        left: 44%;
    }

    .sign-up-mobile .divider span {
        left: 44%;
    }
    .sign-up-mobile .divider span,
    .sign-in-mobile .divider span {
        font-size: 1.1rem;
    }

    .sign-in-mobile {
        padding: 0 5%;
    }

    .sign-up-mobile .btn,
    .sign-in-mobile .btn {
        font-size: 1.1rem !important;
    }

    .sign-up-mobile .login_logo img {
        width: 45% !important;
    }

    .sign-up-mobile h1 {
        margin-bottom: 20px;
    }

    .sign-up-mobile .btn {
        font-size: 0.75rem;
    }

    .sign-up-mobile .btn.google,
    .sign-up-mobile .btn.apple,
    .sign-up-mobile .btn.facebook {
        padding: 15px;
    }

    .sign-up-mobile .google img,
    .sign-up-mobile .apple img,
    .sign-up-mobile .facebook img {
        width: 22px;
    }

    .sign-up-mobile .divider span {
        top: -13px;
    }

    .sign-up-mobile input {
        font-size: 1.1rem;
        padding: 15px;
    }

    .sign-up-mobile .password-container {
        flex-direction: column;
    }

    .sign-up-mobile .password-div input {
        padding-right: 45px;
    }

    .sign-up-mobile .password-div img {
        width: 22px;
        top: 16px;
        right: 15px;
    }

    .sign-in-mobile .links label {
        position: relative;
        top: 1px;
    }

    .sign-up-mobile .terms input[type='checkbox'] {
        width: 18px;
        height: 18px;
    }

    .sign-in-mobile .right-side .links #forgot-password-text {
        color: var(--primary-color);
        cursor: pointer;
    }

    .sign-up-mobile button[type='submit'] {
        width: 100%;
        padding: 14px;
    }

    .additional-details-modal input,
    .forgot-password-modal input,
    .resend-email-modal input {
        font-size: 0.75rem;
        padding: 8px;
    }

    .sign-up-mobile {
        font-size: 1.1rem;
    }
}

/* For medium mobile devices */

@media screen and (max-width: 400px) {
    .sign-in-mobile {
        font-size: 1rem;
    }

    .sign-up-mobile {
        font-size: 1rem;
    }

    .sign-up-mobile .google img,
    .sign-up-mobile .apple img,
    .sign-up-mobile .facebook img {
        width: 20px;
    }

    .sign-up-mobile .login_logo img {
        width: 50%;
    }

    .sign-in-mobile h1 {
        margin-bottom: 20px;
    }

    .sign-in-mobile .btn {
        font-size: 1rem !important;
    }

    .sign-up-mobile .btn {
        font-size: 1rem !important;
    }

    .sign-in-mobile .btn.google,
    .sign-in-mobile .btn.apple,
    .sign-in-mobile .btn.facebook {
        padding: 15px;
    }

    .sign-in-mobile .google img,
    .sign-in-mobile .apple img,
    .sign-in-mobile .facebook img {
        width: 20px;
    }

    .sign-up-mobile .divider,
    .sign-in-mobile .divider {
        margin: 20px 0px;
    }

    .sign-in-mobile .divider span {
        top: -10px;
    }

    .sign-up-mobile .login_logo img {
        width: 48% !important;
    }

    .sign-in-mobile .login_logo img {
        width: 48% !important;
    }

    .sign-up-mobile .divider span,
    .sign-in-mobile .divider span {
        font-size: 1rem;
    }

    .sign-up-mobile form,
    .sign-in-mobile form {
        margin-top: 40px;
    }

    .sign-in-mobile input {
        font-size: 1rem;
        padding: 15px;
    }

    .sign-up-mobile input {
        font-size: 1rem;
        padding: 10px;
    }

    .sign-in-mobile .password-div input {
        padding-right: 45px;
    }

    .sign-in-mobile .password-div img {
        width: 20px;
        top: 12px;
        right: 15px;
    }

    .sign-up-mobile h1,
    .sign-in-mobile h1 {
        font-size: 1.8rem;
    }

    .sign-up-mobile .password-div img {
        width: 20px;
        top: 14px;
        right: 15px;
    }

    .sign-in-mobile .links input[type='checkbox'] {
        width: 18px;
        height: 18px;
    }

    .sign-in-mobile button[type='submit'] {
        width: 100%;
        padding: 14px;
    }
}

/* For small mobile devices */

@media screen and (max-width: 330px) {
    .sign-up-mobile {
        align-items: flex-start;
    }

    .sign-up-mobile,
    .sign-in-mobile {
        padding: 0 7%;
        font-size: 0.925rem;
    }

    .sign-in-mobile .password-div img {
        top: 17px;
    }

    .sign-up-mobile .login_logo img {
        width: 50% !important;
    }

    .sign-up-mobile .password-div img {
        top: 17px;
    }

    .sign-in-mobile .google img,
    .sign-in-mobile .apple img,
    .sign-in-mobile .facebook img {
        width: 18px;
    }

    .sign-up-mobile {
        font-size: 0.925rem;
    }

    .sign-up-mobile .google img,
    .sign-up-mobile .apple img,
    .sign-up-mobile .facebook img {
        width: 18px;
    }

    .sign-in-mobile .btn.google,
    .sign-in-mobile .btn.apple,
    .sign-in-mobile .btn.facebook {
        font-size: 0.925rem !important;
    }

    .sign-up-mobile .btn.google,
    .sign-up-mobile .btn.apple,
    .sign-up-mobile .btn.facebook {
        font-size: 0.925rem !important;
    }

    .sign-up-mobile .divider span,
    .sign-in-mobile .divider span {
        left: 43%;
        padding: 0 10px;
    }

    .sign-in-mobile input {
        font-size: 0.925rem;
        padding: 15px;
    }

    .sign-up-mobile input {
        font-size: 0.925rem;
        padding: 15px;
    }

    .sign-in-mobile .btn {
        font-size: 0.925rem !important;
    }

    .sign-up-mobile .btn {
        font-size: 0.925rem !important;
    }

    .sign-up-mobile .divider span,
    .sign-in-mobile .divider span {
        font-size: 0.925rem;
    }

    .sign-in-mobile .login_logo img {
        width: 50% !important;
    }
}

/* For foldable mobile devices */

@media screen and (max-width: 300px) {
    .sign-up-mobile .login_logo img {
        width: 60%;
    }

    .sign-in-mobile .login_logo img {
        width: 58%;
    }

    .sign-up-mobile .divider span,
    .sign-in-mobile .divider span {
        left: 43%;
        padding: 0 10px;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}
