.Email {
    height: 40px;
    background-color: antiquewhite;
    position: sticky;
    top: 1;
}

.Email_action {
    color: #007bff;
    cursor: pointer;
}

.Email_close {
    cursor: pointer;
}

.Email_content {
    font-size: 14px;
}

.email_wrapper_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 700px) {
    .Email {
        height: 60px;
    }

    .email_wrapper_flex {
        align-items: center;
        gap: 30px;
    }
}
