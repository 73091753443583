.VerifyEmail {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.VerifyEmail_text {
    font-size: 20px;
    font-weight: bold;
    color: darkgreen;
}
