.user-profile {
    padding: 30px 0 50px;
}

.user-profile .display-picture {
    display: flex;
    justify-content: center;
    width: 100%;
}

.profile-image {
    border-radius: 50%;
    width: 100%;
    position: relative;
}

.user-profile .display-picture > div {
    position: relative;
    width: 100px;
    height: 100px;
}

.profile-wrapper {
    position: absolute;
    top: 70%;
    right: 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
}

.user-profile .display-picture label img {
    opacity: 1;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.user-profile .display-picture label .overlay {
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100px;
    height: 94%;
    border-radius: 100%;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    cursor: pointer;
}

.user-profile .display-picture label .overlay i {
    color: var(--text-dark-color);
    font-size: 2.5rem;
}

.user-profile .display-picture > div:hover img {
    opacity: 0.3;
}

.user-profile .general-details,
.user-profile .social-details .social-details-input {
    margin-top: 20px;
}

.user-profile .user-profile-details input {
    font-family: inherit;
    padding: 12px 15px;
    outline: 0;
    border: 1px solid #cccbcb;
    border-radius: 30px;
}

.user-profile .social-details {
    margin-top: 30px;
}

.user-profile .user-profile-details .btn {
    margin-top: 30px;
    font-size: 0.875rem;
    /* box-shadow: 0px 12px 35px rgba(160, 121, 0, 0.2); */
}

.input-field {
    display: flex;
    flex-direction: column;
}

.error-field-profile {
    margin: 5px 0 0px;
}

.profile-pic {
    height: 100%;
}

.security-div {
    margin-top: 50px;
}

.security-div h5 {
    margin-bottom: 20px;
}

.delete-account-btn {
    margin-top: 10px;
    font-size: 0.875rem;
    background-color: transparent;
    color: red;
    border: 1px solid red;
}

@media screen and (max-width: 1000px) {
    .user-profile {
        padding: 90px 0 50px;
    }
}

@media screen and (max-width: 500px) {
    .user-profile .user-profile-details .grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .user-profile .user-profile-details .btn {
        width: 100%;
    }

    .user-profile h4 {
        font-size: 1.4rem;
    }

    .social-details > h5 {
        font-size: 1.2rem;
    }

    .security-div h5 {
        font-size: 1.2rem;
    }

    .logo {
        max-width: 160px;
    }
}

@media screen and (max-width: 330px) {
    .user-profile .display-picture > div {
        width: 100px;
    }

    .user-profile .user-profile-details input {
        font-size: 0.75rem;
    }

    .user-profile .user-profile-details .btn {
        font-size: 0.75rem;
    }
}
