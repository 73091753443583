.navbar-mobile {
    display: none;
}

.navbar-sidebar {
    display: none;
}

.navbar {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 80px;
    font-size: 0.875rem;
    font-weight: 500;
    background-color: var(--text-light-color);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.navbar .flex {
    justify-content: space-between;
}

.navbar-left {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.navbar-left ul {
    list-style-type: none;
}

.navbar-right .btn {
    padding: 12px 35px;
    font-size: 0.875rem;
    font-weight: 500;
}

.navbar-right .profile {
    position: relative;
    cursor: pointer;
    color: var(--primary-color);
}

.navbar-right .profile.flex {
    gap: 10px;
    justify-content: flex-end;
}

.navbar-right .profile span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
}

.navbar-right .profile span img {
    width: 14px !important;
    margin-left: 2px;
}

.profile-pic {
    border-radius: 50%;
}

.navbar-right .profile img {
    width: 50px;
    height: 50px;
}

.logo {
    max-width: 150px;
    margin-right: 30px;
}

.logo > a {
    margin: 0 !important;
}

.dropdown-chevron-down {
    position: absolute;
    width: 14px;
    margin-top: 4px;
    margin-left: 2px;
    margin-right: 15px;
}

.navbar ul {
    display: flex;
    color: var(--primary-color);
}

.navbar ul button,
.navbar ul a {
    color: var(--primary-color);
}

.navbar .navbar-left ul a {
    margin: 0 15px;
    background-color: transparent;
    border: 0;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
    font-family: inherit;
}

.navbar button {
    color: var(--text-light-color);
    margin: 0 15px;
    background-color: transparent;
    border: 0;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
    font-family: inherit;
}

.navbar .btn.btn-light {
    background-color: var(--text-light-color);
}

.courses-card-list,
.profile-list {
    position: absolute;
    height: auto;
    z-index: 5;
}

.profile-list.card {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(162, 182, 235, 0.15);
    margin-top: 20px;
    margin-left: 0;
}

.profile-list {
    margin-top: -8px !important;
}

.profile-list.card {
    padding: 10px 8px;
}

.profile-list ul {
    display: block;
}

.profile-list a {
    margin: 0;
    color: var(--primary-color);
}

.profile-list ul a:last-child {
    color: rgb(238, 70, 70);
}

.profile-list ul li {
    width: 100%;
    font-size: 0.8125rem;
    padding: 8px 10px;
}

.profile-list ul li:hover {
    background-color: rgba(237, 236, 247, 0.5);
}

.profile-list ul li i {
    margin-right: 5px;
}

@keyframes close-animation {
    0% {
        opacity: 1;
        left: 0px;
    }

    100% {
        opacity: 0;
        left: -280px;
    }
}

@media screen and (min-width: 1920px) {
    .navbar .container {
        max-width: 100%;
    }
}

@media screen and (min-width: 2800px) {
    .navbar .container {
        max-width: 80%;
    }
}

@media screen and (min-width: 4800px) {
    .navbar .container {
        max-width: 70%;
    }
}

@media screen and (max-width: 1200px) {
    @keyframes sidebar-animation {
        0% {
            opacity: 0;
            left: -280px;
        }

        100% {
            opacity: 1;
            left: 0;
        }
    }

    .logo {
        max-width: 170px;
    }

    .sidebar-header > div {
        font-size: 1.2rem;
    }

    .user-profile {
        padding: 80px 0 50px !important;
    }

    nav {
        display: none;
    }
    .navbar-mobile {
        display: block;
        width: 100%;
        height: 55px;
        position: fixed;
        z-index: 10;
        background-color: var(--text-light-color);
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.25);
    }

    .navbar-mobile .container > div {
        cursor: pointer;
    }

    .navbar-mobile .container {
        padding: 0 40px;
    }

    .navbar-mobile .flex {
        overflow: hidden;
    }

    .navbar-mobile .flex .hamburger {
        flex: 0.1;
        margin-top: 5px;
        margin-right: auto;
    }

    .navbar-mobile .hamburger img {
        width: 20px;
    }

    .navbar-mobile .flex .logo {
        flex: 0.8;
        margin-top: 5px;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        padding: 15px;
    }

    .navbar-mobile .flex .logo img {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .navbar-mobile .flex .profile-mobile {
        flex: 0.1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: auto;
        margin-right: 5px;
        width: 100%;
    }

    .navbar-mobile .flex .profile-mobile img {
        width: 40px;
        height: 40px;
    }

    .profile-mobile-list {
        position: absolute;
        top: 42px;
        right: 22px;
        z-index: 5;
    }

    .profile-mobile-list.card {
        padding: 5px 8px;
        border-radius: 6px;
    }

    .profile-mobile-list ul {
        font-size: 0.825rem;
        font-weight: 500;
        color: var(--primary-color);
    }

    .profile-mobile-list ul a {
        color: var(--primary-color);
    }

    .profile-mobile-list ul a:last-child {
        color: rgb(238, 70, 70);
    }

    .profile-mobile-list ul li {
        display: flex;
        align-items: center;
        padding: 5px;
    }

    .profile-mobile-list ul li i {
        margin-right: 5px;
    }

    .navbar-sidebar {
        display: block;
        width: 100%;
        height: 100vh;
        position: fixed;
        z-index: 20;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .sidebar {
        position: absolute;
        width: 280px;
        height: 100vh;
        background-color: var(--text-light-color);
        transition: 0.2s linear;
        animation: sidebar-animation 0.2s;
    }

    .sidebar .sidebar-header {
        position: relative;
        padding: 15px 0;
        font-weight: 500;
        color: var(--text-dark-color);
        background-color: #f7f9fa;
    }

    .sidebar .sidebar-header .back {
        position: absolute;
        margin-left: 15px;
        cursor: pointer;
    }

    .sidebar ul {
        font-size: 0.9375rem;
    }

    .sidebar ul li:first-child {
        margin-top: 5px;
    }

    .sidebar ul li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        margin: 0px 15px;
        margin-bottom: 15px;
        color: var(--text-dark-color);
        border-bottom: 1px solid #327ae7;
        cursor: pointer;
    }

    .close-sidebar {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 20px;
        left: 295px;
        background-color: var(--text-light-color);
        border-radius: 100%;
        cursor: pointer;
        transition: 0.2s ease-in-out;
    }

    .close-sidebar > div {
        width: 40%;
    }
}

@media screen and (max-width: 700px) {
    .profile-mobile-list {
        right: 12px;
    }

    .navbar-mobile .container {
        padding: 0 25px;
    }
}

@media screen and (max-width: 500px) {
    .navbar-mobile .flex .logo {
        max-width: 150px;
    }
}

@media screen and (max-width: 400px) {
    .profile-mobile-list ul {
        font-size: 0.75rem;
        font-weight: 500;
        color: var(--primary-color);
    }

    .navbar-mobile .container {
        padding: 0 15px;
    }
}

@media screen and (max-width: 400px) {
    .sidebar {
        width: 250px;
    }

    .close-sidebar {
        left: 265px;
    }
}
