.AdditionDetail h6 {
  font-weight: 500;
}

.AdditionDetail_UserImg img {
  width: 20%;
}

.AdditionDetail input {
  font-family: inherit;
  font-size: 0.875rem;
  width: 100%;
  margin-top: 10px;
  padding: 10px 8px;
  border: 1px solid var(--text-primary-color);
  border-radius: 5px;
  outline: 0;
}

.AdditionDetail_Error {
  margin-top: 10px;
  color: #e44042;
}
