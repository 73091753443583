.error {
    position: relative;
    height: 100vh;
}

.error #top-illustration,
.error #bottom-illustration {
    width: 18%;
}

.error .logo {
    max-width: 150px;
    margin-right: 30px;
}

.error #top-illustration {
    position: absolute;
    top: 0;
    right: 0;
}

.error #bottom-illustration {
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.error .header {
    padding: 20px 0;
}

.error .error-div {
    height: 80vh;
}

.error .error-div .grid {
    gap: 0;
}

.error .error-div .left h1 {
    display: inline-block;
    position: relative;
}

.error .error-div .left h1::after {
    content: '';
    top: 60px;
    left: 0;
    position: absolute;
    width: 60%;
    height: 5px;
    background: linear-gradient(90deg, #e2801c -3.42%, #f7a654 107.37%);
}

.error .error-div .left p {
    color: var(--primary-color);
    margin-top: 20px;
}

.error .error-div .left .btn {
    margin-top: 10px;
    font-size: 0.875rem;
}

.error .error-div .right .icon {
    width: 85%;
}

@media screen and (max-width: 1200px) {
    .error #top-illustration,
    .error #bottom-illustration {
        width: 30%;
    }
    .error .error-div .right .icon {
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .error .error-div .left h1::after {
        top: 30px;
    }

    .container {
        padding: 0 25px;
    }

    .error .error-div .grid {
        grid-template-columns: repeat(1, 1fr);
        place-content: center;
        gap: 50px;
    }

    .error .error-div .left h1::after {
        top: 50px;
    }

    .error .error-div .right .icon {
        width: 85%;
    }
}

@media screen and (max-width: 700px) {
    .error .error-div .grid {
        padding: 0 15%;
    }
}

@media screen and (max-width: 500px) {
    .container {
        padding: 0 25px;
    }
    .error .error-div .grid {
        padding: 0 5%;
    }

    .error .error-div .left h1::after {
        top: 40px;
        height: 3px;
    }

    .error .error-div .right .icon {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .container {
        padding: 0 15px;
    }
}

@media screen and (max-width: 330px) {
    .container {
        padding: 0 15px;
    }
}
