@keyframes modal {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.Modal {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    animation: modal 0.2s;
}

.Modal_Center {
    align-items: center;
}

.Modal::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.Modal::-webkit-scrollbar-thumb,
.Modal::-webkit-scrollbar-track {
    background: transparent;
}

.Modal_Card {
    margin: 50px 10px;
    border-radius: 10px;
    box-shadow: none;
}

.Modal_Content {
    width: 30%;
}

.Modal_Content__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--primary-color);
    padding-bottom: 5px;
    border-bottom: 1px solid #cecdcd;
}

.Modal_Content__Header i {
    color: var(--text-dark-color);
    cursor: pointer;
}

.Modal_Content__Body {
    margin: 20px 0;
    font-size: 0.875rem;
}

.Modal_Content__Footer {
    display: flex;
    flex-direction: row-reverse;
}

@media screen and (max-width: 700px) {
    .Modal_Content {
        margin: 20px 10px;
        width: 100%;
    }

    .Modal_Center {
        align-items: flex-start;
    }
}

@media screen and (max-width: 400px) {
    .Modal_Content {
        margin: 20px 10px;
        width: 100%;
    }
    .Modal_Content__Header {
        font-size: 1rem;
    }
    .Modal_Content__Body {
        font-size: 0.75rem;
    }

    .Modal_Content__Footer .btn {
        padding: 8px 20px;
        font-size: 0.75rem;
    }
}
